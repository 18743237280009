<template>
  <div id="storyContainer">
    <div class="image-container d-flex flex-row flex-1 py-3" ref="imgContainer">
      <div class="story-control d-flex flex-column justify-content-center align-items-center" :class="sidemenuOpen ? 'hide-on-mobile' : ''">
        <div id="previous" class="px-2" @click="pageIndex--" v-show="hasPrevious && !showDetails"><i ref="prev" class="icon-angle-left"></i></div>
        <div id="previous" class="px-2" @click="showDetails = false" v-show="showDetails"><i ref="prev"  class="icon-angle-left"></i></div>
      </div>

      <div class="image flex-1" :class="sidemenuOpen ? 'hide-on-mobile' : ''" ref="img" :style="{backgroundImage: getBgImage}">
        <!-- hidden image for sso and screen readers -->
        <img v-if="isImage()" :src="this.method.contributionstorypage[this.pageIndex].image" :alt="getItemAttr(this.method.contributionstorypage[this.pageIndex], 'image_alt_text', $i18n.locale)" id="alt-img" aria-hidden="false">
        <video-background :src="this.method.contributionstorypage[this.pageIndex].image" ref="video"></video-background>
        <div id="citation" class="pt-3 pr-2 pb-1 pl-2" v-html="getItemAttr(this.method.contributionstorypage[this.pageIndex], 'image_description', $i18n.locale)"></div>
      </div>

      <div class="story-control d-flex flex-column justify-content-center align-items-center">
        <div class="prev-mobile px-2 mobile-only" @click="pageIndex--" v-show="(hasPrevious && !showDetails) && sidemenuOpen"><i ref="prev" class="icon-angle-left"></i></div>
        <div class="prev-mobile px-2 mobile-only" @click="showDetails = false" v-show="showDetails && sidemenuOpen"><i ref="prev"  class="icon-angle-left"></i></div>

        <div id="next" class="px-2" @click="pageIndex++; $refs.menu.scrollToTop()" v-show="hasNext"><i ref="next" class="icon-angle-right"></i></div>
        <div id="next" class="px-2" @click="showDetails = true" v-show="!hasNext && !showDetails"><i class="icon-angle-right"></i></div>
      </div>
    </div>

    <map-sidemenu ref="menu" @toggle="ontoggle" forceArrow>
      <FrontpageSection :title="getItemAttr(method, 'title', $i18n.locale)" v-if="!showDetails">
        <template v-slot:above_title>
          <Breadcrumbs ref="breadcrumbs" :current-display-name="getItemAttr(method, 'title_short', $i18n.locale)" :override-parent="backlink" class="story-breadcrumb"></Breadcrumbs>
        </template>
        <span id="author">{{singleAuthor}} {{createdAt}}</span>
        <div v-if="isLoggedIn" class="status" :style="{'background-color': method.contribution_status_color}" :title="method.contribution_status"></div>
        <Accordion id="header-accordion" class="partner-accordion mb-4" :title="getItemAttr(this.method.contributionstorypage[this.pageIndex], 'name', $i18n.locale)" :level="this.method.contributionstorypage[this.pageIndex].level">
          <p v-html="getItemAttr(this.method.contributionstorypage[this.pageIndex], 'text', $i18n.locale)"></p>
        </Accordion>
      </FrontpageSection>
      <FrontpageSection v-if="showDetails" :title="getItemAttr(method, 'title', $i18n.locale)">
        <Accordion id="header-accordion" class="partner-accordion mb-4" :title="$t('method_detail.more_info')" :level="3">
          <p>
            <router-link v-if="method.expert_page" :to="{ name: 'moreInfo', params: { id: method.id } }" target="_blank" class="pb-2">{{$t('method_detail.more_info_link')}}</router-link>
          </p>
          <div class="topic-title"><strong v-html="$t('method_detail.more_info_text')"></strong></div>
          <div class="topic-text" v-html="getItemAttr(method, 'further_information', $i18n.locale)"></div>
        </Accordion>
        <Accordion id="header-accordion" class="partner-accordion mb-4" :title="$t('method_detail.authors')">
          <div class="bullet-list">
            <div class="bullet-list-item d-flex">
              <div class="mr-2"><i class="icon-play"></i></div>
              <router-link :to="{path: '/partners/#' + method.first_author.partner}" target="_blank">{{method.first_author.name}}</router-link>
            </div>
          </div>
          <div class="bullet-list" v-for="author in method.further_authors" :key="author">
            <div class="bullet-list-item d-flex">
              <div class="mr-2"><i class="icon-play"></i></div>
              <router-link :to="{path: '/partners/#' + author.partner}" target="_blank">{{author.name}}</router-link>
            </div>
          </div>
        </Accordion>
      </FrontpageSection>
      <SubtleFooter map></SubtleFooter>
    </map-sidemenu>
  </div>

</template>

<script>
import MapSidemenu from "@/components/map/MapSidemenu";
import FrontpageSection from "@/components/frontpage/Section";
import Accordion from "@/components/core/Accordion";
import SubtleFooter from "@/components/SubtleFooter";
import Breadcrumbs from "@/components/Breadcrumbs";
import { getItemAttr, isLoggedIn } from "@/tools";

export default {
  name: "Story",

  components: {
    MapSidemenu,
    FrontpageSection,
    Accordion,
    SubtleFooter,
    Breadcrumbs,
  },

  props: {
    contrib_id: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    pages: [],
    pageIndex: 0,
    method: {
      contributionstorypage: { 0: {} },
    },
    loaded: false,
    firstUpdate: true,
    showDetails: false,
    video: false,
    isLoggedIn: false, // TODO: load this from the server!
    sidemenuOpen: true,
  }),

  methods: {
    getItemAttr: getItemAttr,
    next() {
      // this.pageIndex = this.pageIndex++;
      this.$refs.menu.$el.scrollTo(0, 0);
    },
    prev() {
      // this.pageIndex = this.pageIndex--;
      this.$nextTick(() => {
        this.$refs.menu.$el.scrollTop = 0;
      });
    },
    percent(px) {
      let screenWidth = window.screen.width;
      return (screenWidth - px) / screenWidth;
    },
    ontoggle(open) {
      this.sidemenuOpen = open;

      if (open) {
        this.$refs.imgContainer.style.marginRight = String(this.$refs.menu.$el.clientWidth) + "px";
        try {
          this.$refs.vid.style.width = String(this.$refs.menu.$el.clientWidth) + "px";
          this.$refs.vid.style.left = "4%";
        } catch {
          // ignore missing video element
        }
      } else {
        try {
          this.$refs.vid.style.width = "100%";
          this.$refs.vid.style.left = "0";
        } catch {
          // ignore missing video element
        }
        this.$refs.imgContainer.style.marginRight = "0px";
      }
    },
    toggleVid() {
      try {
        if (this.$refs.vid.playing) {
          this.$refs.vid.pause();
        } else {
          this.$refs.vid.play();
        }
      } catch {}
    },
    isImage() {
      let image = null;
      try {
        let path_array = this.method.contributionstorypage[this.pageIndex].image.split("/");
        image = path_array[path_array.length - 1].split(".")[1] !== "mp4";
        console.log(image);
      } catch {
        image = true;
      }
      return image;
    },
    load() {
      fetch(this.$apiURL + "/contributions/" + this.trueId, { credentials: "include" }).then((response) => {
        console.log("fetched");
        return response.json();
      }).then((method) => {
        this.method = method;
        this.loaded = true;
        console.log("loading ready");
      });
    },

    hideOnMobileMenuOpen() {
      if (this.sidemenuOpen) {
        return true;
      } else {
        return false;
      }
    },
  },

  async created() {
    this.load();
    this.isLoggedIn = await isLoggedIn();
    this.pageIndex = Number(window.location.hash.substring(1));
  },

  updated() {
    // TODO: Needs to be triggered on windows resize also
    if (this.$refs.menu.showSidemenu || this.firstUpdate) {
      this.$refs.imgContainer.style.marginRight = String(this.$refs.menu.$el.clientWidth) + "px";
      try {
        this.$refs.vid.style.width = String(this.$refs.menu.$el.clientWidth) + "px";
        this.$refs.vid.style.left = "4%";
      } catch {}
      this.firstUpdate = false;
    }

    let json = JSON.stringify({
      text: this.method.expert_page,
      text_de: this.method.expert_page_de,
      text_en_GB: this.method.expert_page_en_GB,
      title: this.method.title,
      title_de: this.method.title_de,
      title_en_GB: this.method.title_en_GB,
    });
    localStorage.setItem("expert_" + this.method.id, json);
  },

  computed: {
    trueId() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    hasNext() {
      return (this.method.contributionstorypage.length !== (this.pageIndex + 1));
    },
    hasPrevious() {
      return (this.pageIndex !== 0);
    },
    title() {
      if (this.$i18n.locale === "en_GB") {
        return this.method.title_en_GB;
      } else {
        return this.method.title;
      }
    },
    createdAt() {
      return new Date(this.method.created_at).getFullYear();
    },
    encodedExpertPage() {
      let data = { default: this.method.expert_page, de: this.method.expert_page_de, en: this.method.expert_page_en_GB };
      return btoa(JSON.stringify(data));
    },
    singleAuthor() {
      var first = this.method.first_author;
      var further = this.method.further_authors;

      try {
        if (further.length === 0) {
          if (first) {
            return first.name;
          } else {
            return "";
          }
        } else {
          if (first) {
            return first.name + " et al.";
          } else {
            return "";
          }
        }
      } catch {
        if (first) {
          return first.name + " et al.";
        } else {
          return "";
        }
      }
    },
    getBgImage() {
      let img = null;
      try {
        img = this.method.contributionstorypage[this.pageIndex].image;
      } catch {
        img = null;
      }
      if (img) {
        return `url(${img})`;
      } else {
        return "";
      }
    },
    backlink() {
      if (this.type === "Method") {
        return { disp: this.$t("link.methods"), name: "methodIntro", last: false };
      } else {
        return { disp: this.$t("link.scenarios"), name: "scenarioIntro", last: false };
      }
    },
  },
  watch: {
    "$route"() {
      this.pageIndex = Number(window.location.hash.substring(1));
    },
  },
};

</script>

<style scoped lang="scss">
  #storyContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    //position: relative;
  }

  .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex: 1;
    transition: margin-right 700ms;
    //position: relative;
  }

  @media (max-width: rem($break-md - 1)) {
    .hide-on-mobile {
      display: none !important;
    }
  }

  .pager {
    //z-index: 1000;
    //position: absolute;
    font-size: 50pt;
    //top: 45%;
    cursor: pointer;
    line-height: 0.1;

    i {
      text-shadow: 5px 0 10px #ffffff;
      opacity: 60%;
    }
  }

  #next {
    @extend .pager;
    //right: 0;
  }

  #previous,
  .prev-mobile {
    @extend .pager;
    //left: 0;
  }

  //@media screen and (min-width: rem($break-md)) {
  //  .prev-mobile {
  //    display: none;
  //  }
  //}
  //
  //@media screen and (max-width: rem($break-md - 1)) {
  //  #previous,
  //  .image,
  //  .hide-mobile {
  //    display: none !important;
  //  }
  //}

  .show {
    display: block !important;
  }

  #alt-img {
    display: none;
  }

  #header-accordion {
    margin-top: 2em;
  }

  #citation {
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: rem(16);
    text-shadow: 1px 1px 3px #444;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.68) 73%, rgba(0, 0, 0, 0.81) 100%);
  }

  .video {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: width 700ms;
  }

  .status {
    height: rem(30);
    width: rem(30);
    border-radius: 50%;
    display: inline-block;
    margin-left: 60%;
  }

  .story-breadcrumb {
    margin-top: -1rem;
  }

  .image-container {
    position: relative;
  }

  .story-control {
    min-width: rem(55);
  }

  //@media (max-width: rem($break-md - 1)) {
  //  .image-container > .story-control {
  //    &:first-child {}
  //
  //    &:last-child {}
  //  }
  //}
</style>
