<template>
  <div class="page home" :class="{'phone': isPortrait}">
    <frontpage-banner-slider :slides="langSlider"></frontpage-banner-slider>

    <frontpage-section :title="baseText('home_title')">
      <div v-html="baseText('home_text')"></div>
    </frontpage-section>

    <frontpage-section :title="$t('start_page.elements')">
      <div class="element-container">
        <frontpage-element :title="$t('link.methods')" :shortDescription="$t('link-description.methods')" detailRoute="methodIntro"></frontpage-element>
        <frontpage-element :title="$t('link.scenarios_new_line')" :shortDescription="$t('link-description.scenarios')" detailRoute="scenarioIntro"></frontpage-element>
        <frontpage-element :title="$t('link.roadmaps')" :shortDescription="$t('link-description.roadmaps')" detailRoute="roadmaps"></frontpage-element>
        <frontpage-element :title="$t('link.partner')" :shortDescription="$t('link-description.partner')" detailRoute="partners"></frontpage-element>
      </div>
    </frontpage-section>

    <frontpage-section :title="$t('start_page.partners')">
      <frontpage-partner-slider></frontpage-partner-slider>
    </frontpage-section>

    <frontpage-section :title="$t('start_page.finance')">
      <p>
        {{ $t('start_page.finance_text')}}
      </p>
    </frontpage-section>

    <frontpage-footer></frontpage-footer>
  </div>
</template>

<script>
import FrontpageElement from "@/components/frontpage/Element";
import FrontpageFooter from "@/components/frontpage/Footer";
import FrontpageBannerSlider from "@/components/frontpage/BannerSlider";
import FrontpagePartnerSlider from "@/components/frontpage/PartnerSlider";
import FrontpageSection from "@/components/frontpage/Section";
import { fetchPartner } from "@/api";


export default {
  components: {
    FrontpageElement,
    FrontpageFooter,
    FrontpageBannerSlider,
    FrontpagePartnerSlider,
    FrontpageSection,
  },

  computed: {
    isPortrait() {
      return this.$store.getters.isPortrait;
    },
    langSlider() {
      if (this.$i18n.locale.startsWith("de")) {
        return this.slides.de;
      } else {
        return this.slides.en;
      }
    },
  },

  methods: {
    async initialize() {
      fetchPartner(partners => {
        this.partners = partners;
      });

      fetch(this.$apiURL + "/base_texts/").then(response => { return response.json(); }).then(obj => {
        this.baseTexts = obj.results;
      });
    },
    goto(id) {
      window.open("/partner_detail/" + id);
    },
    baseText(key) {
      console.log(this.$i18n.locale);
      if (this.$i18n.locale.startsWith("de")) {
        var neededText = this.baseTexts.find(x => x.key === key);
      } else {
        neededText = this.baseTexts.find(x => x.key === key + "_en_GB");
      }
      return neededText._val_string;
    },
  },

  data: () => ({
    partners: null,
    baseTexts: null,
    slides:
      {
        de: [
          {
            id: 1,
            image: require("@/assets/img/slider/iStock-973190966_c_Orbon Alija.jpg"),
            text: `
            <div class="container">
                <span class="prominent">NETTO-NULL-2050 WEB-ATLAS</span>
                <span>Informationen für die</span>
                <span> öffentliche und politische</span>
                <span> debatte über CO<sub>2</sub> -Neutralität.</span>
            </div>`,
          },
          {
            id: 2,
            image: require("@/assets/img/slider/iStock-862090574_c_franckreporter.jpg"),
            text: `
            <div class="container">
                <span class="prominent">NETTO-NULL-2050 WEB-ATLAS</span>
                <span>Beiträge aus der Wissenschaft</span>
                <span> zur CO<sub>2</sub> -Neutralität</span>
                <span> in Deutschland bis 2050.</span>
            </div>`,
          },
          {
            id: 3,
            image: require("@/assets/img/slider/iStock-1097184316_c_kokouu.jpg"),
            text: `
            <div class="container">
              <span class="prominent">NETTO-NULL-2050 WEB-ATLAS</span>
              <span>STRATEGIEN UND NEUE WEGE</span>
              <span> ZUR REDUZIERUNG, Vermeidung</span>
              <span>UND ENTNAHME VON CO<sub>2</sub> - EMISSIONEN</span>
              <span>IN DEUTSCHLAND.</span>
            </div>`,
          },
        ],
        en: [
          {
            id: 1,
            image: require("@/assets/img/slider/iStock-973190966_c_Orbon Alija.jpg"),
            text: `
            <div class="container">
                <span class="prominent">NET-ZERO-2050 WEB-ATLAS</span>
                <span>Information for the</span><br>
                <span> public and political</span><br>
                <span> discussion about carbon neutrality.</span>
            </div>`,
          },
          {
            id: 2,
            image: require("@/assets/img/slider/iStock-862090574_c_franckreporter.jpg"),
            text: `
            <div class="container">
                <span class="prominent">NET-ZERO-2050 WEB-ATLAS</span>
                <span>Contributions from science</span><br>
                <span> About carbon neutrality</span><br>
                <span> in germany by 2050.</span><br>
            </div>`,
          },
          {
            id: 3,
            image: require("@/assets/img/slider/iStock-1097184316_c_kokouu.jpg"),
            text: `
            <div class="container">
              <span class="prominent">NET-ZERO-2050 WEB-ATLAS</span>
              <span>strategies and new ways</span><br>
              <span> for avoiding, reducing and removing</span><br>
              <span> carbon emissions in germany</span>
            </div>`,
          },
        ],
      },
  }),

  created() {
    this.initialize();
  },
};

</script>

<style lang="scss">
  .element-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: rem(20);
  }

  @media (min-width: rem($break-sm)) {
    .element-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: rem($break-md - 1)) {
    .element-container {
      display: block;

      > .element:not(:last-child) {
        margin-bottom: rem(20);
      }
    }
  }

  .prominent{
    font-weight: bold;
  }
</style>
