<template>
  <div class="top-text" v-html="baseText(txt)"></div>
</template>

<script>
export default {
  name: "DynText",
  props: {
    txt: String,
  },
  data() {
    return {
      baseTexts: [],
    };
  },
  methods: {
    baseText(key) {
      console.log(this.$i18n.locale);
      let neededText;
      if (this.$i18n.locale.startsWith("de")) {
        neededText = this.baseTexts.find(x => x.key === key);
      } else {
        neededText = this.baseTexts.find(x => x.key === key + "_en_GB");
      }
      if (neededText) {
        return neededText._val_string;
      } else {
        return "";
      }
    },
  },
  created() {
    fetch(this.$apiURL + "/base_texts/").then(response => { return response.json(); }).then(obj => {
      this.baseTexts = obj.results;
    });
  },
};
</script>

<style scoped lang="scss">
.top-text{
  margin-bottom: 30px;

  --x-linear-scale-min-value: 16;
  --x-linear-scale-max-value: 22;
  @include linear-scale((font-size))
}
</style>
