import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars, faTimes, faPlane, faShip, faRadiation, faMinusCircle, faCheckCircle, faTimesCircle,
  faGlobeEurope, faTruck, faHome, faTractor, faTrash, faCircle,
  faIndustry, faBolt, faExchangeAlt, faTree, faLock, faStar,
  faLeaf, faRoad, faBookOpen, faPlay, faMicrochip, faUsers, faChevronDown, faChevronLeft, faChevronRight, faMapMarkedAlt, faShapes, faSearch,
  faArrowRight, faArrowLeft, faComment,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VTooltip from "v-tooltip";
import Notifications from "vue-notification";
import { i18n } from "./plugins/i18n";
import router from "@/router";
import "vueperslides/dist/vueperslides.css";
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "@/assets/scss/main.scss";
import vClickOutside from "v-click-outside";
import VueTables from "vue-tables-2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueFormulate from "@braid/vue-formulate";
import { getMeta } from "@/tools";
import FormulateHtml from "@/components/forms/FormulateHtml";
import VideoBackground from "vue-responsive-video-background-player";

Vue.component("v-select", vSelect);

Vue.use(vClickOutside);
Vue.use(VueTables.ClientTable);
Vue.use(VideoBackground);

Vue.component("FormulateHtml", FormulateHtml);
Vue.use(VueFormulate, {
  library: {
    html: {
      classification: "html",
      component: "FormulateHTML",
      slotProps: {
        component: ["html"],
      },
    } } });

library.add(faBars, faTimes, faPlane, faShip, faRadiation, faMinusCircle, faCheckCircle, faTimesCircle,
  faRadiation, faGlobeEurope, faTruck, faHome, faTractor, faCircle,
  faTrash, faIndustry, faBolt, faExchangeAlt, faTree, faLock, faStar,
  faLeaf, faRoad, faBookOpen, faPlay, faMicrochip, faUsers, faChevronDown, faChevronLeft, faChevronRight, faMapMarkedAlt, faShapes, faSearch,
  faFacebookF, faTwitter, faLinkedinIn, faInstagram, faYoutube,
  faArrowRight, faArrowLeft, faComment);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VTooltip);
Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.prototype.$apiURL = getMeta("api-url-" + process.env.NODE_ENV);
console.log("node_env: " + process.env.NODE_ENV);

window.app = new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount("#app");
