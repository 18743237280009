<template>
  <div class="page partners-map d-flex flex-column">
    <map-sidemenu ref="overlay" @toggle="resize">
      <div v-if="detailMode">
        <FrontpageSection :title="partner.name + ' (' + partner.short_name + ')'" v-if="partner !== {}">
          <template v-slot:above_title>
            <Breadcrumbs class="breadcrumbs"></Breadcrumbs>
          </template>
          <div class="partner-top-flex">
            <div class="bullet-list top-flex-item">
              <div class="bullet-list-item d-flex" v-for="cooperation in partner.netto0_cooperation" v-bind:key="cooperation.id">
                <div class="mr-2"><i class="icon-play"></i></div>
                <span v-if="$i18n.locale === 'de'">{{cooperation.project_name}}</span>
                <span v-else>{{cooperation.project_name_en_GB}}</span>
              </div>
            </div>
            <img id="logo" :src="partner.logo" alt="">
          </div>
          <x-accordion class="partner-accordion mb-4" :title="$t('partner.portrait')" :arrow="false">
            <p v-if="$i18n.locale === 'de'" v-html="partner.short_portrait"></p>
            <p v-else v-html="partner.short_portrait_en_GB"></p>
          </x-accordion>
<!--          <x-accordion class="mb-4" :title="$t('partner.c02_save')" :arrow="false">-->
<!--            <div class="bullet-list">-->
<!--              <div class="bullet-list-item d-flex" v-for="scenario in partner.netto0_cooperation" :key="scenario.id">-->
<!--                <div class="mr-2"><i class="icon-play"></i></div>-->
<!--                <span v-if="$i18n.locale === 'de'">{{scenario.project_name}}</span>-->
<!--                <span v-else>{{scenario.project_name_en_GB}}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </x-accordion>-->
          <x-accordion class="mb-4" :title="$t('partner.contact')" :arrow="false">
            <div class="partner-top-flex">
              <div v-if="partner.address">
                {{partner.address.street}} {{partner.address.house_number}}<br>
                {{partner.address.postal_code}} {{partner.address.city}}<br><br>
                <a style="color: #005aa0;" :href="partner.weblink">Website</a>
              </div>
              <div class="partner-image-wrapper">
                <img id="image" width="250px" :src="partner.image"/>
                <div class="image-description">{{partner.image_copyright}}</div>
              </div>
            </div>
          </x-accordion>
        </FrontpageSection>
      </div>
      <div v-else ref="partnerList">
        <FrontpageSection :title="$t('map.partner_title')">
          <DynText txt="partner_text"></DynText>
          <div v-for="partner in allPartners" :key="partner.id" @click="showPartner(partner.id, false, true)" class="partner-list-item">
            <img :src="partner.logo" class="list-logo">
          </div>
        </FrontpageSection>
      </div>
      <SubtleFooter map></SubtleFooter>
    </map-sidemenu>
    <PinMap :lat="52" :lon="19" :showPins="true" :initial="Number(hash)" ref="map" @rendered="ready"></PinMap>
  </div>
</template>

<script>
import MapSidemenu from "@/components/map/MapSidemenu";
import PinMap from "@/components/map/PinMap";
import FrontpageSection from "@/components/frontpage/Section";
import XAccordion from "@/components/core/Accordion";
import SubtleFooter from "@/components/SubtleFooter";
import DynText from "@/components/DynText";
import Breadcrumbs from "@/components/Breadcrumbs";
import { getName } from "i18n-iso-countries";

export default {
  name: "PartnersMap.vue",

  components: {
    MapSidemenu,
    PinMap,
    FrontpageSection,
    XAccordion,
    SubtleFooter,
    DynText,
    Breadcrumbs,
  },
  data() {
    return {
      partner: {},
      detailMode: false,
      allPartners: [],
    };
  },
  methods: {
    showPartner(id, highlight = false, zoom = false) {
      this.$refs.overlay.resetScroll();
      fetch(this.$apiURL + "/partner/" + id).then(
        (response) => {
          return response.json();
        }).then((partner) => {
        this.partner = partner;
        if (this.partner.name) {
          this.$refs.overlay.open();
          this.$refs.overlay.forceArrow = true;
          this.detailMode = true;
          this.$refs.map.activateMarker(id, zoom);
          if (highlight) {
            this.$refs.map.highlightPartner();
          }
        }
      });
    },
    country(id) {
      return getName(id);
    },

    ready() {
      if (window.location.hash) {
        console.log("has hash");
        let id = window.location.hash.slice(1);
        this.showPartner(id, false, true);
      }
    },
  },
  mounted() {
    console.log("mounted");
    console.log("api_url: " + this.$apiURL);
  },
  created() {
    fetch(this.$apiURL + "/partner/").then(response => { return response.json(); }).then(obj => {
      this.allPartners = obj.results;
    });
  },
  computed: {
    hash() {
      return window.location.hash.slice(1);
    },
  },
  watch: {
    "$route"() {
      let hash = window.location.hash.slice(1);
      if (hash) {
        this.showPartner(hash);
        this.$refs.overlay.open();
      } else {
        this.detailMode = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .partner-list-item{
      width: 100%;
    }
  .partner-top-flex{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  #logo{
    max-width: 15em;
    margin-left: 1em;
    margin-right: 3em;
  }

  .partner-accordion{
    margin-top: 2em;
  }

  .image-description {
    text-align:center;
    width: 250px;
    font-size: 15px;
  }

  .list-logo{
    max-height: 100px;
    width: 40%;
    margin-bottom: 2em;
    margin-left: 28%;
    //cursor: pointer;
  }

  .partner-name{
  }

  .breadcrumbs{
    margin-bottom: 1em;
  }

  @media screen and (min-width: 768px){
    .partner-image-wrapper{
      margin-top: 3em;
      margin-left: 20%;
    }
  }
</style>
