<template>
  <div id="map"></div>
</template>

<script>
import map from "./Map";
import * as Leaflet from "leaflet";
import { countryList } from "@/constants/countryList";
import { mapStyles } from "@/constants/mapStyles";
import { fetchRoadmaps } from "@/api";

export default {
  name: "CountryMap",

  extends: map,

  props: {
    highlightCountries: Boolean,
    global: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      geoJson: {},
      roadmaps: [],
      tooltipFeature: {},
      selectedCountry: "",
    };
  },

  methods: {
    // Begin of map events
    mapClick(e) {
      // check if the country has data
      if (e.target.hasData) {
        // if a country is selected clear it
        if (this.selectedCountry !== "") {
          this.selectedCountry.setStyle(mapStyles.hasData);
        }
        // set the style of the new country to selected
        e.target.setStyle(mapStyles.selected);
        this.selectedCountry = e.target;

        // center the map on the selected country
        this.map.setView({ lon: e.latlng.lng + 20, lat: e.latlng.lat }, 5);

        // emit the click event to the parent
        this.$emit("country", e.target.feature.properties.ISO_A2);
      }
    },
    highlightFeature(e) {
      const layer = e.target;
      layer.setStyle(layer.hasData ? mapStyles.hoveredWithData : mapStyles.hovered);
      this.$emit("hover", e.target.feature);
    },
    resetHighlight(e) {
      if (e.target.hasData && e.target !== this.selectedCountry) {
        e.target.setStyle(mapStyles.hasData);
      }
      this.$emit("reset");
    },
    onEachFeature(feature, layer) {
      // only enable events if countries are highlighted
      if (this.highlightCountries) {
        layer.on({
          click: this.mapClick,
          mouseover: this.highlightFeature,
          mouseout: this.resetHighlight,
        });
      }
    },
    // End of map events

    highlightCountriesWithData(roadmaps) {
      const countriesWithData = new Set();
      // iterate over the roadmaps from the server and add them to the set
      for (const e of roadmaps) {
        countriesWithData.add(e.country.short_name);
      }
      // highlight the countries with data and set the has data property
      this.geoJson.eachLayer(layer => {
        const countryCode = layer.feature.properties.ISO_A2;
        if (countriesWithData.has(countryCode) && layer.setStyle) {
          layer.hasData = true;
          layer.setStyle(mapStyles.hasData);
        }
      });
    },
    highlightSelected(short_name) {
      this.geoJson.eachLayer(layer => {
        if (layer.feature.properties.ISO_A2 === short_name) {
          if (this.selectedCountry !== "") {
            this.selectedCountry.setStyle(mapStyles.hasData);
          }

          this.selectedCountry = layer;

          layer.setStyle(mapStyles.selected);

          // center capitol
          const capitol_lng = parseInt(layer.feature.capitol_coords.lng);
          const capitol_lat = parseInt(layer.feature.capitol_coords.lat);
          this.map.setView({ lat: capitol_lat, lon: capitol_lng + 20 }, 5);
        }
      });
    },
    loadMap() {
      console.log("mounted country map");
      // init the map
      this.init(this.global);
      this.loadBase();

      // add the country polygons
      this.geoJson = Leaflet.geoJson(countryList, {
        style: mapStyles.base,
        onEachFeature: this.onEachFeature,
      }).addTo(this.map);

      if (this.highlightCountries) {
        // fetchRoadmaps(roadmaps => {
        //   this.highlightCountriesWithData(roadmaps);
        // });
      }
    },

    reset() {
      this.selectedCountry.setStyle(mapStyles.hasData);
    },
  },

  mounted() {
    this.loadMap();
  },
};
</script>

<style scoped lang="scss">
</style>
