<template>
  <div>
    <div class="page more-info">
      <frontpage-section :title="getTitle()">
        <template v-slot:above_title>
            <div class="breadcrumbs">
              <router-link :to="{name: 'home'}">
                <font-awesome-icon class="home-icon" icon='home'/>
              </router-link>
              <span class="bc-divider">></span>
            <router-link :to="{name: 'scenarioIntro'}">
                <span v-if="$i18n.locale.startsWith('de')" class="bc-item">Pfade</span>
                <span v-else class="bc-item">Paths</span>
            </router-link>
            <span class="bc-divider">></span>
            <router-link :to="{name: 'scenario', id: method.id }">
                <span v-if="$i18n.locale.startsWith('de')" class="bc-item" v-html="method.title_de"></span>
                <span v-else class="bc-item" v-html="method.title_en_GB"></span>
            </router-link>
            <span class="bc-divider">></span>
            <span v-if="$i18n.locale.startsWith('de')" class="bc-item">Mehr Info</span>
            <span v-else class="bc-item">More Info</span>
          </div>
        </template>
        <p v-html="parseContentHtml(content, 'text', $i18n.locale)"></p>
      </frontpage-section>
    </div>
    <subtle-footer class="footer"></subtle-footer>
  </div>
</template>

<script>
import FrontpageSection from "@/components/frontpage/Section";
import { getItemAttr } from "@/tools";
import SubtleFooter from "@/components/SubtleFooter";

export default {
  name: "MoreInfo",
  components: {
    FrontpageSection,
    SubtleFooter,
  },
  data() {
    return {
      content: "",
      method: {},
    };
  },
  methods: {
    getItemAttr: getItemAttr,
    getTitle() {
      let title = this.$t(`more_information`);
      title += ' "';
      title += getItemAttr(this.content, "title", this.$i18n.locale);
      title += '"';
      return title;
    },
    parseContentHtml(content, attr, locale) {
      let html_text = this.getItemAttr(content, attr, locale, true);
      let root_elem = document.createElement("span");
      root_elem.innerHTML = html_text;

      let images = root_elem.getElementsByTagName("img");
      for (let image of images) {
        image.parentNode.classList.add("image-container");

        let citation = image.getAttribute("title");
        let p = document.createElement("small");
        p.classList.add("caption");
        p.innerHTML = citation;

        image.classList.add("dyn-image");
        image.after(p);
      }

      return root_elem.outerHTML;
    },
    load() {
      fetch(this.$apiURL + "/contributions/" + this.$route.params.id, { credentials: "include" }).then((response) => {
        console.log("fetched");
        return response.json();
      }).then((method) => {
        this.method = method;
        this.content = {
          text: method.expert_page,
          text_de: method.expert_page_de,
          text_en_GB: method.expert_page_en_GB,
          title: method.title,
          title_de: method.title_de,
          title_en_GB: method.title_en_GB,
        };
      });
    },
  },
  created() {
    this.load();
    console.log("ergebniss: ", localStorage.getItem("x-net"), localStorage.locale);
  },
};
</script>

<style lang="scss">
  .more-info{
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .caption{
    display: block;
    margin-left: 5px;
  }

  .image-container {
    display: inline-block;
  }

    .breadcrumbs{
    display: flex;
    flex-direction: row;

    a{
      color: black;
      font-size: 17px;
    }

    a:hover{
      border-bottom: 1px solid black;
    }

    span{
      font-size: 17px;
    }
  }

  .bc-divider{
    margin: 0 10px 0 10px;
    font-family: "Roboto", "sans-serif";
    font-weight: 300;
    color: rgb(112, 112, 112);
    font-size: 17px;
  }

  .bc-item{
    font-family: "Roboto", "sans-serif";
    color: rgb(112, 112, 112);
    font-weight: 300;
  }

  .home-icon{
    color: rgb(112, 112, 112);
    font-weight: 300;
  }

  .footer{
    position: fixed;
    bottom: 0;
  }
</style>
