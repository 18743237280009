<template>
  <div id="map" :style="{width: width}"></div>
</template>

<script>
import Map from "@/components/map/Map";
import { fetchPartner } from "@/api";
import * as Leaflet from "leaflet";
import { greenIcon, blueIcon } from "@/markers";

export default {
  name: "PinMap",

  extends: Map,

  props: {
    showPins: Boolean,
    opacity: Number,
    initial: Number,
  },

  data() {
    return {
      chosenPartner: {},
      markerClusters: {},
      existingMarkers: {},
      width: "100%",
      panActivated: true,
    };
  },

  methods: {
    generatePopupImage(partner) {
      let anchor = document.createElement("a");
      let image = document.createElement("img");

      image.height = 50;
      console.log(image.height);
      image.src = partner.logo;

      anchor.href = "#" + partner.id;
      anchor.appendChild(image);

      return anchor;
    },
    addPartnerToMap(partners) {
      for (let i = 0; i < partners.length; i++) {
        let partner = partners[i];
        let popupImage = this.generatePopupImage(partner);
        if (partner.latitude && partner.longitude) {
          var marker = Leaflet.marker([partner.latitude, partner.longitude]);

          marker.bindPopup(popupImage, {});
          if (partner.id === this.initial) {
            marker.setIcon(greenIcon);
          }
          this.existingMarkers[partner.id] = marker;

          this.markerClusters.addLayer(marker);
        }
      }
      this.$emit("rendered");
    },
    highlightPartner(id) {
      this.renderLayers();
    },
    clearActivations() {
      // deactivate all markers
      for (const [key, value] of Object.entries(this.existingMarkers)) {
        this.markerClusters.removeLayer(value);
        value.setIcon(blueIcon);
        this.markerClusters.addLayer(value);
      }
    },
    activateMarker(id, logoClick = false) {
      this.clearActivations();
      // activate the selected marker
      var marker = this.existingMarkers[Number(id)];

      this.markerClusters.removeLayer(marker);
      marker.setIcon(greenIcon);
      this.markerClusters.addLayer(marker);

      if (logoClick) {
        console.log("layers", marker._layers);
        var marker_center = marker.getLatLng();

        let that = this;
        this.markerClusters.zoomToShowLayer(marker, function(e, t) {
          that.map.flyTo({ lat: marker_center.lat, lon: marker_center.lng / 0.7 });
        });
      }
    },
  },
  mounted() {
    this.init();
    this.loadBase();

    // set up marker clusters
    this.markerClusters = Leaflet.markerClusterGroup({
      iconCreateFunction: function(cluster) {
        return Leaflet.divIcon({ className: "cluster-icon", iconSize: 30, html: `<span class="cluster-number">${cluster.getChildCount()}</span>` });
      },
    });

    // add the partner pins if desired
    if (this.showPins) {
      fetchPartner(partners => {
        this.addPartnerToMap(partners);
      });

      this.markerClusters.on("clusterclick", (pos) => {
        this.panActivated = true;
        this.map.on("zoomend", () => {
          if (this.panActivated) {
            // get the outer bounds of the maps viewport and subtract them from each other -> angle width of the viewport
            // multiply it with 0.25 to get 25% the shown 25% of the map,
            let difference = (this.map.getBounds().getEast() - this.map.getBounds().getWest()) * 0.25;

            // add the difference to the longitude to get the center of the visible map.
            this.map.panTo({ lat: this.map.getCenter().lat, lon: this.map.getCenter().lng + difference });
            this.panActivated = false;
          }
        });
      });
      this.map.addLayer(this.markerClusters);
    }
    if (this.layerUrl) {
      this.renderLayers();
    }
  },
};
</script>

<style scoped lang="scss">
</style>

